const ContactsPage = () => {
  return (
    <div>
      Наш номер телефона:
      <a href="tel:+79000000000">+79000000000</a>
    </div>
  );
};

export default ContactsPage;
